import * as React from "react"
//import component list
import GlobalIntro from '../components/global-intro'
import FourBucket from '../components/four-bucket'
import GlobalDuplexBanner from '../components/global-duplex-banner'

const ContentArea = ({layoutPieces}) => {
    console.log({layoutPieces})
    if (layoutPieces) {
      return layoutPieces?.map((layoutPiece, index) => {
        return ([
          layoutPiece.internal.type === 'ContentfulGlobalIntro' && <GlobalIntro layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulSuiteCardsComponent' && <FourBucket layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulDuplexFeature' && <GlobalDuplexBanner layoutPiece={layoutPiece} key={index}/>,
        ])
      })
    }
}

export default ContentArea
