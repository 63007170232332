import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from '../components/layout'
import ogMetaImage from '../../static/entratalogo_1200x630.png'
import '../styles/404page.css'
import FourOFourContentArea from '../templates/404-content-area'

export default function FourOFourPage({ data, pageContext }) {
  const layoutPieces = data.contentful404Page.layoutPieces
  return (
    <Layout>
      <FourOFourContentArea layoutPieces={layoutPieces}/>
    </Layout>
  )
}

export const Head = ({data}) => (
  <>
    <meta property="og:title" content="404" />
    <meta property="og:description" content="Oops, looks like that page doesn’t exist. Try the helpful links below to get back on track." />
    <meta property="og:url" content="https://www.entrata.com/404" />
    <title>404</title>
    <meta name="description" content="Oops, looks like that page doesn’t exist. Try the helpful links below to get back on track." />
    <meta property="og:image" content={ogMetaImage} />
  </>
)

export const query = graphql`
  query FourOFourPage {
    contentful404Page {
      layoutPieces {
        ... on Node {
          internal {
            type
          }
        }
      }
      layoutPieces {
        ... on ContentfulDuplexFeature {
          ...DuplexFeature
        }
        ... on ContentfulGlobalIntro {
          introText
          introTitle
          introButton {
            buttonOutlined
            buttonText
            slug
            fullUrl
          }
        }
        ... on ContentfulSuiteCardsComponent {
          suiteCards {
            backgroundColor
            headlineText
            exploreMoreLink {
              slug
              fullUrl
              text
            }
            description {
              description
            }
          }
        }
      }
    }
  }
`
